<script setup>
const timer = ref(null);
const currentIndex = ref(0);

const next = function () {
  if (currentIndex.value === 2) {
    currentIndex.value = 0;
  } else {
    currentIndex.value += 1;
  }
};

const forceStepSelection = function (index) {
  clearInterval(timer.value);
  currentIndex.value = index;
};

const startSlide = function () {
  timer.value = setInterval(next, 4000);
};

onMounted(() => {
  startSlide();
});
onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<template>
  <section class="say-hello-section">
    <div class="say-hello-wrapper">
      <div class="say-hello-container">
        <div class="say-hello-heading">
          <h2
            class="sh-heading-title"
            v-html="$t('t_CHATHEADER')"
          />
          <p class="sh-heading-subtitle">
            {{ $t('t_CHATBODY') }}
          </p>
        </div>
        <div class="sh-chat-windows">
          <img
            class="sh-chat-window"
            :class="{ 'step-active': currentIndex === 0 }"
            src="https://a.hwstatic.com/image/upload/f_auto,h_656,w_375,q_50/v1645433308/pwa/chat/chat-hostel.png"
            alt="Chat Hostel"
            loading="lazy"
            width="375"
            height="656"
          />
          <img
            class="sh-chat-window"
            :class="{ 'step-active': currentIndex === 1 }"
            src="https://a.hwstatic.com/image/upload/f_auto,h_656,w_375,q_50/v1645433308/pwa/chat/chat-city.png"
            alt="Chat City"
            loading="lazy"
            width="375"
            height="656"
          />
          <img
            class="sh-chat-window"
            :class="{ 'step-active': currentIndex === 2 }"
            src="https://a.hwstatic.com/image/upload/f_auto,h_656,w_375,q_50/v1645433308/pwa/chat/chat-direct.png"
            alt="Chat Direct"
            loading="lazy"
            width="375"
            height="656"
          />
        </div>
        <img
          class="sh-chat-image small"
          src="https://a.hwstatic.com/image/upload/f_auto,h_226,w_370,q_50/v1644506518/pwa/chat/chat-image-1-sm.jpg"
          alt="Chat Image 1"
          loading="lazy"
          height="226"
          width="370"
        />
        <img
          class="sh-chat-image large top-left"
          :class="`step-active-${currentIndex}`"
          src="https://a.hwstatic.com/image/upload/f_auto,h_480,w_320,q_50/v1644506520/pwa/chat/chat-image-1.jpg"
          alt="Chat Image 2"
          loading="lazy"
          width="320"
          height="480"
        />
        <img
          class="sh-chat-image large bottom-right"
          :class="`step-active-${currentIndex}`"
          src="https://a.hwstatic.com/image/upload/f_auto,h_480,w_320,q_50/v1644506518/pwa/chat/chat-image-2.jpg"
          alt="Chat Image 3"
          loading="lazy"
          width="320"
          height="480"
        />
        <div class="sh-steps-wrapper">
          <img
            :class="`dashed-circle step-${currentIndex + 1}`"
            src="https://a.hwstatic.com/image/upload/v1645433308/pwa/chat/dashed-circle.svg"
            alt="Dashed Circle"
            loading="lazy"
            width="33"
            height="33"
          />
          <div
            :class="`step ${currentIndex === 0 && 'step-active'}`"
            @click="forceStepSelection(0)"
          >
            {{ $t('t_LINKUPSHIGHLIGHT1') }}
          </div>
          <div
            :class="`step ${currentIndex === 1 && 'step-active'}`"
            @click="forceStepSelection(1)"
          >
            {{ $t('t_LINKUPSHIGHLIGHT2') }}
          </div>
          <div
            :class="`step ${currentIndex === 2 && 'step-active'}`"
            @click="forceStepSelection(2)"
          >
            {{ $t('t_LINKUPSHIGHLIGHT3') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.say-hello-section {
  overflow-x: hidden;

  .say-hello-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: wds-rem(-84px);
    margin-right: 0;
    position: relative;

    .say-hello-container {
      position: relative;
      min-width: wds-rem(420px);
      max-width: wds-rem(900px);
      margin: 0 auto;
      display: flex;
      flex-direction: column-reverse;
      gap: $wds-spacing-xxl;

      .say-hello-heading {
        margin-left: wds-rem(84px);
        padding: 0 $wds-spacing-m;

        .sh-heading-title {
          @include title-2-bld;

          margin-bottom: $wds-spacing-m;

          :deep(> span) {
            color: $wds-color-purple;

            &::after {
              content: '\a';
              white-space: pre;
            }
          }
        }

        .sh-heading-subtitle {
          @include title-6-reg;
        }
      }

      .sh-chat-image {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $wds-border_radius-xl;

        &.large {
          display: none;
        }
      }

      .sh-chat-windows {
        position: relative;
        margin: wds-rem(156px) auto 0 wds-rem(192px);
        height: wds-rem(430px);
        width: wds-rem(246px);
        box-shadow: 0 11px 21px rgba($wds-color-black, 0.15);
        border-radius: $wds-border_radius-xxl;
        z-index: 1;

        .sh-chat-window {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &:nth-child(2),
          &:nth-child(3) {
            display: none;
          }
        }
      }

      .sh-steps-wrapper {
        display: none;
      }
    }
  }

  @include tablet {
    overflow-x: unset;

    .say-hello-wrapper {
      margin-left: 0;

      .say-hello-container {
        width: 100%;
        max-width: wds-rem(650px);
        flex-direction: row;
        gap: $wds-spacing-xxl;

        .say-hello-heading {
          margin-top: wds-rem(226px);
          margin-left: $wds-spacing-xs;
          padding: 0;
          flex: 1;

          .sh-heading-title {
            @include title-1-bld;

            margin-top: wds-rem(55px);
          }
        }

        .sh-chat-windows {
          height: wds-rem(480px);
          width: wds-rem(280px);
          margin: wds-rem(84px) $wds-spacing-l 0 0;
        }
      }
    }
  }

  @media screen and (width >= 1135px) {
    .say-hello-wrapper {
      .say-hello-container {
        display: block;
        max-width: wds-rem(1000px);
        margin-bottom: $wds-spacing-xxxl;

        .say-hello-heading {
          margin: 0 0 $wds-spacing-xl wds-rem(320px);
          padding-left: $wds-spacing-l;

          .sh-heading-title {
            @include lg-title-3-bld;

            margin-top: 0;
          }

          .sh-heading-subtitle {
            max-width: wds-rem(500px);
          }
        }

        .sh-chat-image {
          &.small {
            display: none;
          }

          &.large {
            display: initial;
            border-radius: $wds-spacing-xxl;
            transition: 0.3s;

            &.top-left {
              top: wds-rem(94px);

              &.step-active-1 {
                transform: translateY(55px);
              }

              &.step-active-2 {
                transform: translateY(90px);
              }
            }

            &.bottom-right {
              top: unset;
              left: unset;
              right: 0;
              bottom: -$wds-spacing-xxxl;

              &.step-active-1 {
                transform: translateY(-55px);
              }

              &.step-active-2 {
                transform: translateY(-90px);
              }
            }
          }
        }

        .sh-chat-windows {
          margin-top: $wds-spacing-xl;
          margin-left: wds-rem(312px);
          height: wds-rem(656px);
          width: wds-rem(375px);

          .sh-chat-window {
            display: none;

            &.step-active {
              display: block;
            }
          }
        }

        .sh-steps-wrapper {
          position: absolute;
          left: $wds-spacing-m;
          bottom: $wds-spacing-xxl;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: wds-rem(175px);
          width: wds-rem(995px);

          &::before,
          &::after {
            content: '';
            position: absolute;
            height: wds-rem(50px);
            border-left: 3px dashed $wds-color-ink-lighter;
          }

          &::before {
            left: wds-rem(-42px);
            top: wds-rem(30px);
          }

          &::after {
            left: wds-rem(-42px);
            top: wds-rem(101px);
          }

          .dashed-circle {
            position: absolute;
            width: wds-rem(33px);
            height: wds-rem(33px);
            z-index: 2;
            left: wds-rem(-57px);
            transition: 0.3s;

            &.step-1 {
              top: 0;
            }

            &.step-2 {
              top: wds-rem(74px);
            }

            &.step-3 {
              top: wds-rem(147px);
            }
          }

          .step {
            cursor: pointer;

            @include title-5-bld;

            position: relative;
            max-width: wds-rem(283px);
            max-height: wds-rem(28px);
            transition: 0.3s;

            &::before {
              position: absolute;
              left: wds-rem(-51px);
              top: wds-rem(6px);
              content: '';
              border-radius: 50%;
              background-color: $wds-color-ink-lighter;
              width: wds-rem(21px);
              height: wds-rem(21px);
              box-shadow: 0 0 0 3px $wds-color-white;
              z-index: 1;
            }

            &.step-active {
              &::before {
                background-color: $wds-color-purple;
              }
            }
          }

          .step-active {
            @include title-3-bld;

            line-height: 1.75rem;
            color: $wds-color-purple;
          }
        }
      }
    }
  }
}
</style>
